<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:placeholder="$t('select')"
			:searchable="true"
		>
			<span slot="noOptions">{{ $t('no_options') }}</span>
			<span slot="noResult">{{ $t('no_result') }}</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				selected: this.value,
				options: [
					{value: "cash", text: this.$t('cash_in_advance')},
					{value: "credit_card", text: this.$t('credit_card')},
					{value: "online_credit_card", text: this.$t('online_credit_card')},
					{value: "wire", text: this.$t("wire")},
					{value: "bank_credit", text:this.$t("bank_credit")},
					{value: "bank_credit_cash", text: this.$t("bank_credit_cash")},
					{value: "mail_order", text: this.$t("mail_order")},
					{value: "check", text: this.$t("check")},
					{value: "promissory_note", text: this.$t("promissory_note")},
					{value: "delay", text: this.$t("delay")},
					{value: "education_credit", text: this.$t("education_credit")},
					{value: "other", text: this.$t("other")},
				]
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		created() {
			this.selected = this.setSelected(this.value, this.options)
		},
		methods: {
			handleInput,
			setSelected
		}
	}
</script>
